import {
  TextField, Typography, Button, Paper,
  TableContainer, Table, TableHead, TableRow, TableBody, TableCell,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import React, { FunctionComponent } from 'react';
import TextEditor from '../../../../view/component/text-editor/TextEditor';
import LoadingBackdrop from '../../backdrop/LoadingBackdrop';

interface Props {
  title: string;
  onChangeTitle: (value: string) => void;
  contents: string;
  onChangeContents: (value: string) => void;
  onClickEdit: () => void;
  fileInfos: { id: number; name: string; location: string }[];
  fileInputRef: React.RefObject<HTMLInputElement>;
  onClickAddFiles: () => void;
  onClickDeleteFile: (id: number) => void;
  onClickDelete: () => void;
  isLoading: boolean;
}

const NoticesEditPage: FunctionComponent<Props> = ({
  title, onChangeTitle,
  contents, onChangeContents,
  onClickEdit,
  fileInfos,
  fileInputRef,
  onClickAddFiles,
  onClickDeleteFile,
  onClickDelete,
  isLoading,
}) => {

  return (
    <div style={{ padding: '20px' }}>
      <Typography variant="h4" style={{ marginBottom: '20px' }}>
        공지사항
      </Typography>

      <TextField
        label="제목"
        fullWidth
        variant="outlined"
        margin="normal"
        value={title}
        onChange={(event) => onChangeTitle(event.target.value)}
      />

      <div style={{ marginBottom: '20px' }}>
        <TextEditor
          value={contents}
          onChange={onChangeContents}
          placeholder="내용을 입력해주세요."
        />
      </div>

      <div
        style={{ marginBottom: '60px', display: 'flex', justifyContent: 'space-between' }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={onClickEdit}
        >
          수정하기
        </Button>

        <Button
          variant="contained"
          color="secondary"
          onClick={onClickDelete}
        >
          공지사항 삭제하기
        </Button>

      </div>

      <Typography variant="h5" style={{ marginBottom: '20px' }}>
        첨부 파일
      </Typography>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell align="center">이름</TableCell>
              <TableCell align="center">위치</TableCell>
              <TableCell align="center"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {fileInfos.map((info, index) => (
              <TableRow key={index} hover>
                <TableCell component="th" scope="row">
                  {index + 1}
                </TableCell>
                <TableCell align="center">{info.name}</TableCell>
                <TableCell align="center">{info.location}</TableCell>
                <TableCell align="center">
                  <Button variant="contained" color="secondary" onClick={() => onClickDeleteFile(info.id)}>
                    <DeleteIcon style={{ fontSize: '17px' }} />
                  </Button>
              </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <input
        type="file"
        multiple
        style={{ marginBottom: '20px', marginTop: '20px', display: 'block' }}
        ref={fileInputRef}
      />

      <Button
        variant="contained"
        color="primary"
        onClick={onClickAddFiles}
        style={{  }}
      >
        추가하기
      </Button>
      <LoadingBackdrop
        isShown={isLoading}
      />
    </div>
  );
};

export default NoticesEditPage;

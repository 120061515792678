import React, { useEffect, useRef, useState } from 'react';
import NoticesEditPage from '../../../view/component/page/NoticeEditPage';
import { getNoticesPagePath } from '../../../lib/app-path';
import { CallabtSDK } from 'callabt-sdk';
import PageWrapper from '../../../lib/PageWrapper';
import { navigate, PageProps } from 'gatsby';

const NoticesEdit = ({ location }: PageProps) => {
  const params = new URLSearchParams(location.search);
  const id = params.get("id");
  const noticeId = Number(id);

  const [title, setTitle] = useState('');
  const [contents, setContents] = useState('');
  const [fileInfos, setFileInfos] = useState<{ id: number; name: string; location: string }[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const fileInputRef = useRef<HTMLInputElement>(null);

  const onClickEdit = async () => {
    setIsLoading(true);
    if (confirm('수정하시겠습니까?')) {
      try {
        await CallabtSDK.editNotice({ id: noticeId, title, contents });
        alert('수정 됐습니다.');
        
      } catch(error) {
        console.error(error);
        alert('수정에 실패했습니다.');
      }
    }
    setIsLoading(false);
  };

  const onClickAddFiles = async () => {
    const fileList: File[] = [];
    
    if (fileInputRef.current && fileInputRef.current.files) {
      for(let i = 0; i < fileInputRef.current.files.length; i += 1) {
        fileList.push(fileInputRef.current.files[i]);
      }
    }
    
    if (fileList.length > 0) {
      setIsLoading(true);
      try {
        await CallabtSDK.addFiles({ id: noticeId, files: fileList });
        alert('첨부 파일이 추가 됐습니다.');
        fetch();
      } catch(error) {
        console.error(error);
        alert('첨부 파일 추가에 실패했습니다.');
      }
      setIsLoading(false);
    } else {
      alert('선택된 파일이 없습니다.')
    }
  };

  const onClickDeleteFile = async (id: number) => {
    setIsLoading(true);
    if (confirm('삭제하시겠습니까?')) {
      try {
        await CallabtSDK.deleteNoticeFile({ id });
        alert('삭제 됐습니다.');
        setFileInfos(fileInfos.filter(info => info.id !== id));
      } catch(error) {
        console.error(error);
        alert('삭제에 실패했습니다.');
      }
    }
    setIsLoading(false)
  };

  const onClickDelete = async () => {
    setIsLoading(true);
    if (confirm('삭제하시겠습니까?')) {
      try {
        await CallabtSDK.deleteNotice({ id: noticeId });
        alert('삭제 됐습니다.');
        navigate(getNoticesPagePath());
      } catch(error) {
        console.error(error);
        alert('삭제에 실패했습니다.');
      }
    }
    setIsLoading(false);
  };

  const fetch = async () => {
    setIsLoading(true);
    try {
      const { notice } = await CallabtSDK.getNotice({ id: noticeId });
  
      setTitle(notice.title);
      setContents(notice.contents);
      setFileInfos(notice.files);
    } catch (error) {
      console.error(error);
      alert('공지사항을 불러오는 데 실패했습니다.');
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetch();
  }, []);

  return (
    <PageWrapper>
      <NoticesEditPage
        title={title}
        onChangeTitle={setTitle}
        contents={contents}
        onChangeContents={setContents}
        onClickEdit={onClickEdit}
        fileInfos={fileInfos}
        fileInputRef={fileInputRef}
        onClickAddFiles={onClickAddFiles}
        onClickDeleteFile={onClickDeleteFile}
        onClickDelete={onClickDelete}
        isLoading={isLoading}
      />
    </PageWrapper>
  );
};

export default NoticesEdit;
